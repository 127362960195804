import { FunctionComponent, useEffect, useState } from "react";
import Form from "@rjsf/mui";
import { IChangeEvent } from "@rjsf/core";
import { JSONSchema7 } from "json-schema";
import { customizeValidator } from "@rjsf/validator-ajv8";
import { CartaWidgetFields, CartaWidgets } from "./widgets";
import { IMonitorForm } from "data/formStatus";
import { ISchemaDefinition, ISchemaProperty } from "data/types";

interface CollectionLongFormProps {
  formData: IMonitorForm;
  schema: JSONSchema7;
  onChange: (data: any, hasError: boolean) => void;
}

const validator = customizeValidator({customFormats: {
  'data-url': /^\S+$/
}})

function generateUiSchema(schema: ISchemaProperty, uiSchema: any){
  if(typeof schema === 'boolean'){
    return;
  }

  if(!schema.properties){
    return;
  }

  let properties = Object.entries(schema.properties);

  if(schema.oneOf){
    properties = [...properties, ...schema.oneOf.flatMap(oneOf=>oneOf.properties ? Object.entries(oneOf.properties) : [])]
  }

  uiSchema['ui:order'] = properties.filter(([_, value])=>typeof value.propertyOrder !== 'undefined').sort(([_a, aValue], [_b, bValue])=>{
    aValue.propertyOrder =  aValue.propertyOrder ?? Number.MAX_SAFE_INTEGER
    bValue.propertyOrder =  bValue.propertyOrder ?? Number.MAX_SAFE_INTEGER
    
    return aValue.propertyOrder - bValue.propertyOrder;
  }).map(([key, _])=>{
    return key;
  });

  uiSchema['ui:order'].push('*');

  properties.forEach(([key, value])=>{
    if(value.items){
      uiSchema[key] = {items: {}}
      generateUiSchema(value.items, uiSchema[key]["items"])
      return;
    }

    if(!value.properties){
      return;
    }

    uiSchema[key] = {}
    generateUiSchema(value, uiSchema[key])
  })

}

export const CollectionLongForm: FunctionComponent<CollectionLongFormProps> = ({
  formData,
  schema,
  onChange,
}) => {
  const safeFormData = formData.data ?? undefined;
  const [uiSchema, setUiSchema] = useState<any>({});

  const handleFormChange = (event: IChangeEvent<any>) => {
    onChange(event.formData, event.errors.length > 0);
  };

  useEffect(()=>{
    const uiSchema = {};
    generateUiSchema(schema as ISchemaDefinition, uiSchema);
    setUiSchema(uiSchema);
  }, [schema])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "1rem",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        padding: "1rem",
        backgroundColor: "#f9f9f9",
      }}>
      {/* Render the form using @rjsf/mui v5 for consistent MUI styling */}
      <Form
        schema={schema}
        formData={safeFormData}
        onChange={handleFormChange}
        liveValidate
        showErrorList={false}
        validator={validator}
        fields={CartaWidgetFields}
        widgets={CartaWidgets}
        uiSchema={uiSchema}>
        {/* Render an empty fragment to remove the default submit button */}
        <></>
      </Form>
    </div>
  );
};

export default CollectionLongForm;
