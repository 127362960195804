import { FormEntryContext } from "components/collectionsForm";
import { UserContext } from "components/user";
import { useCallback, useContext, useEffect, useState } from "react";
import { UploadFile } from "./FileChips";
import { uploadFile } from ".";
import { detectMatchingFiles, FileResolution, Resolution } from "./MatchingFileDialog";
import { CartaFSClient } from "@contextualize/cartafs";

export default function useFileUpload(multiple: boolean, currentValue: string | string[], onResult: (result: string | string[])=>void,onError: (e: any)=>void){

    
    const { carta } = useContext(UserContext);
    const schemaContext = useContext(FormEntryContext)
    
    const [uploading, setUploading] = useState<UploadFile[]>([]);
    
    const [actionableFiles, setActionableFiles] = useState<FileResolution[]>([]);

    const [pendingUpload, setPendingUpload] = useState<FileResolution[] | undefined>();

    const [cartaFs, setCartaFs] = useState<CartaFSClient>();

    useEffect(()=>{
        if(!carta){
            return;
        }
        setCartaFs(new CartaFSClient(carta));
    }, [carta])

    useEffect(() => {
        if(!pendingUpload){
            return;
        }
        (async ()=>{
            try {
                const uploadResult = await uploadFile(multiple, cartaFs, schemaContext, currentValue, pendingUpload, setUploading);
                setPendingUpload(undefined);
                onResult(uploadResult);
            } catch (e: any) {
                onError(e);
            }
        })()
    }, [schemaContext, cartaFs, currentValue, multiple, pendingUpload, onError, onResult])

    const onFileAdded = useCallback(async (files: FileList | undefined | null) => {
        if(uploading.length > 0){
            alert("Please wait for current upload to complete before adding more files");
            return;
        }
        if(files){
            const {files: fileResolutions, unresolved} = detectMatchingFiles(files, currentValue);
            if(unresolved > 0){
                if(multiple){
                    setActionableFiles(fileResolutions)
                }else{
                    fileResolutions[0].resolution = Resolution.Replace;
                    setPendingUpload(fileResolutions);
                }
            }else{
                setPendingUpload(fileResolutions);
            }
        }
    }, [currentValue, uploading, multiple])

    return {
        cartaFs,
        schemaContext,
        uploading,
        actionableFiles: {actionableFiles, setActionableFiles},
        pendingUpload: {pendingUpload, setPendingUpload},
        onFileAdded
    }
}