import { CartaFSClient } from "@contextualize/cartafs";
import ChunkedArray from "library/ChunkedArray";
import { UploadFile } from "./FileChips";
import { CreateFile, Key } from "@contextualize/cartafs/dist/types/file";
import { FileResolution, Resolution } from "./MatchingFileDialog";
import Mutex from "library/Mutex";
import { createContext } from "react";


export const CartaFsContext = createContext<{cartaFs: CartaFSClient | undefined}>({cartaFs: undefined});

export async function uploadFile(multiple: boolean, cartaFs: CartaFSClient | undefined, formContext: { projectId: string } | null, existing: string | string[], files?: FileResolution[] | null, setUploading?: React.Dispatch<UploadFile[]>): Promise<string[] | string> {
    if (!cartaFs || !formContext) {
        throw new Error("Error loading document context");
    }
    if (!files) {
        throw new Error("No documents submitted");

    }

    if (!multiple && files.length !== 1) {
        throw new Error("Must select just one file");
    }

    const filesArr = files.filter(f => f.resolution !== Resolution.Skip);

    // Get the uploading status infront of the user ASAP
    if (setUploading) {
        setUploading(filesArr.map((file, index) => {
            return {
                href: `${index}`,
                name: file.file.name,
                progress: 0
            }
        }));
    }


    const existingFiles = ([] as string[]).concat(existing).filter(a => !!a).map(a => FileUri.extract(a));
    let renameMutex = new Mutex();

    // Prefire the upload
    const initiated = await ChunkedArray.from(filesArr, 5).map(async (file) => {
        switch (file.resolution) {
            case Resolution.Create:
                existingFiles.push({
                    name: file.file.name,
                    meta: {file: ""},
                    type: ""
                })
                return {
                    init: await cartaFs.initiateUpload({
                        filename: file.file.name,
                        project: formContext.projectId
                    }),
                    file: file.file,
                    resolution: file.resolution
                }
            case Resolution.Keep:
                let name = file.file.name
                let copy = 1;
                const nameSplit = name.split('.');
                const first = nameSplit[0];
                let match = /\((\d+)\)$/.exec(first);
                let basename = nameSplit[0];
                console.log(match);
                if (match && match[1]) {
                    copy = parseInt(match[1]) + 1;
                    basename = first.slice(0, -match[0].length)
                }
                
                await renameMutex.take()
                const findName = (f: FileUriData) => f.name === name;

                do{
                    nameSplit[0] = `${basename}(${copy})`;
                    name = nameSplit.join('.');
                    copy++;
                }while (existingFiles.find(findName))
                
                existingFiles.push({
                    name: name,
                    meta: {file: ""},
                    type: ""
                })
                renameMutex.release();

                return {
                    init: await cartaFs.initiateUpload({
                        filename: name,
                        project: formContext.projectId
                    }),
                    file: file.file,
                    resolution: file.resolution
                }
            case Resolution.Replace:
                const existing = file.match?.meta;
                if (!existing) {
                    throw new Error("This shouldn't be possible");
                }
                return {
                    init: await cartaFs.initiateUpdate(existing),
                    file: file.file,
                    resolution: file.resolution
                }
            default:
                throw new Error("Should not have come here")
        }
    });

    if (setUploading) {
        let finished = false;
        const progresses = initiated.flatten().map(({ init }) => {
            return {
                name: init.meta.fileMetadata.filename,
                href: init.meta.file,
                progress: 10
            }
        }).reduce((previous, current) => {
            previous[current.href] = current;
            return previous;
        }, {} as Record<string, UploadFile>)

        setUploading(Object.values(progresses));

        const updateStatus = (file: string) => {
            return (progress: number) => {
                if (finished) return;
                progresses[file].progress = 10 + progress * 90;
                setUploading(Object.values(progresses));
            }
        }

        await initiated.forEach(async ({ init, file }) => {
            await cartaFs.uploadWithTracking(init.uploadUrl, file, updateStatus(init.meta.file));
        });

        finished = true;
        setUploading([]);
    } else {
        initiated.forEach(async ({ init, file }) => {
            await cartaFs.uploadToCache(init.uploadUrl, file);
        })
    }

    let appendFiles = initiated.flatten();
    if(multiple){
        appendFiles = appendFiles.filter(f=>f.resolution !== Resolution.Replace)
    }

    const fileIds = appendFiles.map(({ init, file }) => FileUri.to(init.meta, file));

    if (!multiple) {
        return fileIds[0];
    } else {
        return fileIds.concat(existing);
    }
}

export async function downloadFile(cartaFs: CartaFSClient | undefined, fileKey: string) {
    if (!cartaFs) {
        throw new Error("Error loading document context");
    }
    const fileDownlaod = await cartaFs.download(fileKey);
    const link = document.createElement('a');
    link.href = fileDownlaod.downloadUrl;
    link.download = fileDownlaod.meta.fileMetadata.filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export interface FileUriData {
    type: string;
    name: string;
    meta: { file: string };
}

export class FileUri {
    private constructor() { };

    static to(meta: CreateFile & Key, file: File) {
        return `data:${file.type};name=${meta.fileMetadata.filename};cartafs,${meta.file}`
    }

    static extract(uri: string): FileUriData {
        const [type, name, encoding] = uri.split(';');
        return {
            type,
            name: name.split('=')[1],
            meta: { file: encoding.split(',')[1] }
        }
    }
}
