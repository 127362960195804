import { CloudDownload } from "@mui/icons-material";
import { Chip, CircularProgress } from "@mui/material";
import { useCallback, useContext } from "react";
import { CartaFsContext, downloadFile, FileUri } from ".";


export function DownloadChips(props: { fsUrls: string | string[] }) {

    const { cartaFs } = useContext(CartaFsContext);

    const _downloadFile = useCallback(async (fileKey: string) => {
        try {
            await downloadFile(cartaFs, fileKey)
        } catch (e: any) {
            console.error(e);
        }
    }, [cartaFs]);

    return (<>
        {
            ([] as string[]).concat(props.fsUrls).filter(a => !!a).map(FileUri.extract)
            .map((value) => 
                <Chip key={value.meta.file} icon={<CloudDownload />} onClick={(e) => { e.preventDefault(); _downloadFile(value.meta.file) }} label={value.name} />
            )
        }
    </>)
}

export interface UploadFile {
    href: string;
    name: string;
    progress: number;
}

export function UploadingChips(props: { fsUrls: UploadFile | UploadFile[] }) {

    return (<>
        {([] as UploadFile[]).concat(props.fsUrls).filter(a => !!a)
            .map((value) => <Chip key={value.href} avatar={<CircularProgress variant="determinate" size="2em" value={value.progress} />} label={value.name} />)}
    </>)
}
