import { CloudUpload } from "@mui/icons-material";
import { Fab } from "@mui/material";
import { FunctionComponent, useCallback } from "react";
import { minTableColumnWidth } from "./const";
import { HiddenInput } from "components/input";
import DragNDrop from "components/dragNDrop";
import { DownloadChips, UploadingChips } from "components/fileUpload/FileChips";
import MatchingFileDialog from "components/fileUpload/MatchingFileDialog";
import useFileUpload from "components/fileUpload/useFileUpload";
import { CartaFsContext } from "components/fileUpload";

interface FileUploadFieldProps {
    defaultValue: string | string[];
    onChange: (value: string | string[]) => void;
    required: boolean;
    multiple: boolean;
}


export const FileUploadField: FunctionComponent<FileUploadFieldProps> = (props) => {
    const {
        defaultValue,
        multiple,
        onChange
    } = props;

    const onResult = useCallback((result)=>{
        onChange(result);
    }, [onChange])

    const onError = useCallback((e)=>console.log(e.message), [])

    const {
        cartaFs,
        uploading,
        actionableFiles: {actionableFiles},
        pendingUpload: {setPendingUpload},
        onFileAdded
    } = useFileUpload(multiple, defaultValue, onResult, onError);


    return (<CartaFsContext.Provider value={{cartaFs}}>
        <DragNDrop onFilesDropped={(files)=>onFileAdded(files)}>
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.25em",
                alignItems: 'center',
                justifyContent: 'start',
                minWidth: minTableColumnWidth
            }}>
                <Fab
                    component="label"
                    size="medium"
                    color="primary"
                    role={undefined}
                    tabIndex={-1}>
                    <CloudUpload />
                    <HiddenInput
                        type="file"
                        onChange={(event)=>onFileAdded(event.target.files)}
                        multiple={multiple}
                    />
                </Fab>
                <div style={{ display: 'flex' }}>
                    <UploadingChips fsUrls={uploading}/>
                    <DownloadChips fsUrls={defaultValue} />
                </div>
            </div>
        </DragNDrop>
        <MatchingFileDialog onResolution={(result)=>{setPendingUpload(result)}} actionableFiles={actionableFiles}/>
        </CartaFsContext.Provider>
    )
}
