export default class Mutex{
    waiting: (()=>void)[] = [];
    pending: number = 0;

    async take(){
        
        return new Promise<void>(resolve=>{
            this.pending++;
            if(this.pending === 1){
                resolve();
                return;
            }

            this.waiting.push(resolve);
        })
    }

    release(){
        this.pending --;
        const next = this.waiting.shift()
        if(next){
            next();
        }
    }
}