import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, useMediaQuery, useTheme } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { FileUri, FileUriData } from ".";

export enum Resolution{
    None,
    /** Upload Normally */
    Create,
    /** Renames the file */ 
    Keep,
    /** Overwrite the existing file */ 
    Replace,
    /** Drop the new file */ 
    Skip
}

export interface FileResolution{
    file: File;
    match?: FileUriData
    resolution: Resolution;
}

interface MatchingFileDialogProps{
    onResolution: (resultion: FileResolution[] | undefined)=>void;
    actionableFiles: FileResolution[];

}

export function detectMatchingFiles(newFiles: FileList, currentFiles: string | string[]): {
    files: FileResolution[],
    unresolved: number
}{
    const fileArr = Array.from(newFiles);
    const existing = ([] as string[]).concat(currentFiles).filter(a=>!!a).map(a=>FileUri.extract(a));

    const matches = fileArr.map((file)=>{
        const match = existing.find(e=>e.name === file.name);
        return {
            file,
            match: match,
            resolution: !match ? Resolution.Create : Resolution.None
        } as FileResolution
    })

    return {
        files: matches,
        unresolved: matches.filter(f=>f.resolution === Resolution.None).length
    }
}

export default function MatchingFileDialog(props: MatchingFileDialogProps){
    const {actionableFiles, onResolution} = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState<boolean>(false);
    const [forAll, setForAll] = useState<boolean>(false);
    const [current, setCurrent] = useState<number>(0);

    const unresolved = useRef<FileResolution[]>([]);

    // Reset the component when new files are added
    useEffect(()=>{
        unresolved.current = actionableFiles.filter(f=>f.resolution === Resolution.None);
        setOpen(unresolved.current.length > 0);
        setCurrent(0);
        setForAll(false);
    }, [actionableFiles]);

    const setResolution = useCallback((at: number, forAll: boolean, resolution: Resolution)=>{
        return ()=>{
            if(forAll){
                for(let i = at; i < unresolved.current.length; i++){
                    const file = unresolved.current[i];
                    file.resolution = resolution;
                }
                onResolution([...actionableFiles]);
                setOpen(false);
            }else{
                const file = unresolved.current[at];
                file.resolution = resolution;
                setCurrent((v)=>{
                    const newValue = v+1;
                    if(newValue >= unresolved.current.length){
                        console.log(actionableFiles);
                        onResolution([...actionableFiles]);
                        setOpen(false);
                    }
                    return newValue;
                });
                
            }
        }
    }, [actionableFiles, onResolution])

    const cancel = useCallback(()=>{
        setOpen(false);
        onResolution(undefined)
    }, [onResolution])

    const remaining = unresolved.current.length - (current + 1);

    if(remaining < 0){
        return <></>
    }


    return (<Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={cancel} >
        <DialogContent>
            <div>
            Duplicate file '{unresolved.current[current].file.name}' {remaining > 0 ? `and ${remaining} more` : <></>}
            </div>
        </DialogContent>
        <DialogActions>
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <div>
                
             {remaining > 0 ? <FormControlLabel control={<Checkbox checked={forAll} onChange={()=>setForAll(!forAll)}/>} label="Repeat for all"  style={{marginLeft: "8px"}}/> : <></>}
            </div>
            <div>
            <Button onClick={setResolution(current, forAll, Resolution.Skip)} autoFocus color="primary">
                Skip
            </Button>
            <Button onClick={setResolution(current, forAll, Resolution.Keep)} color="primary">
                Keep both
            </Button>
            <Button onClick={setResolution(current, forAll, Resolution.Replace)} color="primary">
                Replace
            </Button>
            <Button onClick={cancel} color="secondary">
                Cancel
            </Button>
            </div>
          </div>
        </DialogActions>
      </Dialog>)
}