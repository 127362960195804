import classNames from 'classnames';
import styles from './styles.module.css';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';



export interface DragNDropProps extends React.BaseHTMLAttributes<HTMLDivElement>{
    onFilesDropped: (files: FileList)=>void;
    children: JSX.Element
}

export const DragNDropContext = createContext<{
    isDragging: boolean;
}>({isDragging: false});


const dragState: {
    closeTimeout?: NodeJS.Timeout,
    setup: boolean
} = {
    closeTimeout: undefined,
    setup: false
}

export function DragNDropWrapper({children}: {children: JSX.Element}){
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useEffect(()=>{
        if(dragState.setup){
            return;
        }
        dragState.setup = true;
        document.addEventListener('dragenter', (event)=>{
            if(isDragging){
                return;
            }

            if(dragState.closeTimeout) clearTimeout(dragState.closeTimeout);
            if(event.dataTransfer?.files){
                setIsDragging(true);
            }
        });
        document.addEventListener('dragover', (event)=>{
            if(dragState.closeTimeout) clearTimeout(dragState.closeTimeout);
        });
        document.addEventListener('dragleave', (event)=>{
            if(dragState.closeTimeout) clearTimeout(dragState.closeTimeout)
            dragState.closeTimeout = setTimeout(()=>{
                setIsDragging(false);
            }, 25)
        });

        document.addEventListener('drop', ()=>{
            setIsDragging(false);
        })
        
    }, [isDragging]);

    return (
        <DragNDropContext.Provider value={{isDragging}}>
            {children}
        </DragNDropContext.Provider>
    )
}

export default function DragNDrop({onFilesDropped: handleFilesDropped, children, ...props}: DragNDropProps){
    
    const [dragTarget, setDragTarget] = useState<boolean>(false);
    const {isDragging} = useContext(DragNDropContext);
    const dragLeaveTimeout = useRef< NodeJS.Timeout | undefined>();


    const fileDropped = useCallback((event: React.DragEvent)=>{
        event.preventDefault();
        const files = event.dataTransfer.files;
        if(files){
            handleFilesDropped(files);
        }

        setDragTarget(false);
    }, [handleFilesDropped])

    const enter = useCallback((event: React.DragEvent)=>{
        event.preventDefault();
        if(dragLeaveTimeout.current) clearTimeout(dragLeaveTimeout.current)
        setDragTarget(true);
    },[])

    const exit = useCallback((event: React.DragEvent)=>{
        event.preventDefault();
        if(dragLeaveTimeout.current) clearTimeout(dragLeaveTimeout.current)
            dragLeaveTimeout.current = setTimeout(()=>{
                setDragTarget(false);
            }, 25)
    }, [])

    const over = useCallback((event: React.DragEvent)=>{
        event.preventDefault();
        if(dragLeaveTimeout.current) clearTimeout(dragLeaveTimeout.current)
    }, [])




    return (
    <div 
    {...props}
    className={classNames({
        [styles.dropzone]: true,
        [styles.droptarget]: dragTarget,
        [styles.availableTarget]: isDragging
    })} onDrop={fileDropped} onDragEnter={enter} onDragLeave={exit} onDragOver={over}>
        {children}
    </div>
    )
}