
export default class ChunkedArray<T> {
    chunks: T[][] = [];

    private constructor(private chunkSize: number) {

    }

    async map<Y>(callback: (value: T, index: number) => Promise<Y>): Promise<ChunkedArray<Y>> {
        const nChunk = new ChunkedArray<Y>(this.chunkSize);
        for(let i = 0; i < this.chunks.length; i++){
            const chunk=this.chunks[i];
            nChunk.chunks.push(await Promise.all(chunk.map<Promise<Y>>(async (element, j) => {
                return await callback(element, this.chunkSize * i + j);
            })))
        }
        return nChunk;
    }

    async forEach(callback: (value: T, index: number) => void): Promise<void> {
        for(let i = 0; i < this.chunks.length; i++){
            const chunk=this.chunks[i];
            await Promise.all(chunk.map<Promise<void>>(async (element, j) => {
                return await callback(element, this.chunkSize * i + j);
            }))
        }
    }

    flatten(): T[] {
        return this.chunks.flatMap(a => a);
    }

    static from<T>(elements: T[], chunkSize: number) {
        const nChunk = new ChunkedArray<T>(chunkSize);
        for (let i = 0; i < elements.length; i += chunkSize) {
            nChunk.chunks.push(elements.slice(i, i + chunkSize));
        }
        return nChunk;
    }
}