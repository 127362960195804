import { FieldProps } from '@rjsf/utils'
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { ViewWeek } from '@mui/icons-material'
import Barcode from 'react-barcode';
import { useCallback, useRef } from 'react';

const preventDefault = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
};

export const PrimaryKeyField = function (props: FieldProps) {
    
    const defaultValue = props.formData;
    const barcode = useRef<HTMLDivElement>(null);

    const handleClickShowBarcode = useCallback(() => {
        const qrWindow = window.open("", "", "width=600,height=400,top=0,left=0");
        if (!qrWindow) {
        return;
        }
        qrWindow.document.write(barcode.current?.innerHTML ?? "");
        qrWindow.document.close();
    }, []);

    const hasError = (props.rawErrors?.length ?? 0) > 0;

    return (
        <FormControl variant='outlined' error={hasError}> 
          <InputLabel htmlFor={props.name} required={props.required}>{props.schema.title}</InputLabel>
          <OutlinedInput
            id={props.name}
            type='text'
            defaultValue={defaultValue}
            required={props.required} 
            onChange={(event)=>{props.onChange(event.target.value);}} 
            label={props.schema.title}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="Print barcode"
                  onClick={handleClickShowBarcode}
                  onMouseDown={preventDefault}
                  onMouseUp={preventDefault}
                >
                  <ViewWeek />
                </IconButton>
              </InputAdornment>
            }
          />
          <div style={{display: 'none'}} ref={barcode}>
            {defaultValue && <Barcode value={defaultValue}/>}
          </div>
        </FormControl>
    );
};