import { Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, Typography } from '@mui/material';
import { ErrorSchema, WidgetProps } from '@rjsf/utils';
import { CloudDownload, CloudUpload } from '@mui/icons-material'
import { useCallback, useRef } from 'react';
import { HiddenInput } from 'components/input';
import DragNDrop from 'components/dragNDrop';
import { DownloadChips, UploadFile, UploadingChips } from 'components/fileUpload/FileChips';
import useFileUpload from 'components/fileUpload/useFileUpload';
import MatchingFileDialog, { FileResolution } from 'components/fileUpload/MatchingFileDialog';
import { CartaFsContext, downloadFile, FileUri } from 'components/fileUpload';
import { CartaFSClient } from '@contextualize/cartafs';


interface UploadFieldProps {
    cartaFs: CartaFSClient | undefined;
    onFileAdded: (files: FileList | null) => void;
    label: string;
    value: string;
    actionableFiles: { actionableFiles: FileResolution[] };
    uploading: UploadFile[];
    pendingUpload: { setPendingUpload: React.Dispatch<React.SetStateAction<FileResolution[] | undefined>> };
}

function SingleFileUpload(props: UploadFieldProps) {

    const { cartaFs, onFileAdded, uploading } = props;

    const extractedValue = props.value ? FileUri.extract(props.value) : undefined;
    const uploadButton = useRef<HTMLLabelElement>(null)
    const singleUpload = uploading.at(0);

    return (
        <div style={{ display: "flex" }}>
            <DragNDrop onFilesDropped={(files) => onFileAdded(files)} style={{ flexBasis: "25%" }}>
                <Card sx={{ minWidth: "100%" }}>
                    <CardActionArea onClick={(e) => {
                        e.preventDefault();
                        if (extractedValue) {
                            downloadFile(cartaFs, extractedValue.meta.file)
                        } else {
                            if (uploadButton.current) {
                                uploadButton.current.click();
                            }
                        }
                    }}>
                        <CardContent>

                            <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                                {props.label}
                            </Typography>

                            <Typography variant="h5" component="div">
                                {extractedValue ? extractedValue.name : "Add File"}
                            </Typography>
                        </CardContent>


                    </CardActionArea>
                    <CardActions>
                        {singleUpload ? (<Button
                            component="button"
                            role={undefined}
                            variant="contained"
                            disabled={true}
                            tabIndex={-1}
                            startIcon={<CircularProgress variant="determinate" size="1em" value={singleUpload.progress} />}
                            sx={{ ml: '0.5em' }}
                        >
                            Processing</Button>) : (<>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUpload />}
                                ref={uploadButton}
                            >
                                {props.value ? "Replace" : "Upload"}
                                <HiddenInput
                                    type="file"
                                    onChange={(event) => onFileAdded(event.target.files)}
                                    multiple={false}
                                />
                            </Button>
                            { extractedValue ? (
                                <Button
                                component="button"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudDownload />}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!extractedValue) return;
                                    downloadFile(cartaFs, extractedValue.meta.file)
                                }}
                                sx={{ml: '0.5em'}}
                                >
                                Download</Button>
                            ) : (<></>) }
                            </>
                        )}

                    </CardActions>
                </Card>
            </DragNDrop>
        </div>
    )
}


function MultipleFileUpload(props: UploadFieldProps) {

    const { onFileAdded, pendingUpload: { setPendingUpload }, actionableFiles: { actionableFiles }, uploading } = props;

    return (

        <>
            <div style={{
                display: 'flex',
                width: "100%",
                height: "100%",
                flexDirection: 'column',
                gap: '0.25em'
            }}>
                <DragNDrop onFilesDropped={(files) => onFileAdded(files)} style={{
                    width: "100%",
                    height: "100%"
                }}>
                    <div style={{
                        display: "flex",
                        position: "relative",
                        flexDirection: 'column',
                        gap: '0.25em', width: "100%",
                        height: "100%"
                    }}>
                        <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 16, mb: 6 }}>
                            {props.label}
                        </Typography>
                        <div style={{
                            position: "absolute",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            inset: "0 0 0 0"
                        }}>
                            <Button
                                component="label"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUpload />}
                            >
                                Upload
                                <HiddenInput
                                    type="file"
                                    onChange={(event) => onFileAdded(event.target.files)}
                                    multiple={true}
                                />
                            </Button>
                        </div>
                    </div>
                </DragNDrop>
                <div style={{
                    display: 'flex',
                    width: "100%",
                    flexDirection: 'row'
                }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <UploadingChips fsUrls={uploading} />
                        <DownloadChips fsUrls={props.value} />
                    </div>
                </div>
            </div>

            <MatchingFileDialog onResolution={(result) => { setPendingUpload(result) }} actionableFiles={actionableFiles} />
        </>
    )
}


export default function FileWidget(props: WidgetProps) {


    const { onChange, value, id } = props;
    const multiple = !!props.multiple;

    const onResult = useCallback((result) => {
        onChange(result);
    }, [onChange])

    const onError = useCallback((e) => {
        onChange(value, {
            '__errors': [e.message]
        } as ErrorSchema, id)
    }, [onChange, value, id])

    const fileUploadHook = useFileUpload(multiple, props.value, onResult, onError);

    return (

        <CartaFsContext.Provider value={{ cartaFs: fileUploadHook.cartaFs }} >
            {multiple ? <MultipleFileUpload {...fileUploadHook} {...props} /> : <SingleFileUpload {...fileUploadHook} {...props} />}
        </CartaFsContext.Provider>
    )


}